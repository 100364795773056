import clsx from "clsx";
import { isAfter, isBefore } from "date-fns";
import { motion } from "framer-motion";
import React, { useState } from "react";
import {
  Check as CheckIcon,
  Edit2 as EditIcon,
  Eye as ViewIcon,
  MapPin,
  Search as SearchIcon,
  Smile as SmileIcon,
  Trash2 as DeleteIcon,
  XCircle as XIcon,
} from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { IconButton } from "../../../../components/IconButton";
import { Modal } from "../../../../components/Modal";
import { PlaintiffRequest } from "../../../../models/PlaintiffRequest";
import {
  deleteRequest,
  editRequestInitial,
  setSelectedRequest,
} from "../../../../redux/actions/requests.actions";

interface Props {
  request: PlaintiffRequest;
  onFeaturedClick: (request: PlaintiffRequest) => void;
}

export const RequestCard: React.FC<Props> = ({ request, onFeaturedClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const handleOnClose = () => {
    setModal(false);
  };
  const handleDelete = () => {
    dispatch(deleteRequest(request));
    handleOnClose();
  };

  const backgroundColorToolBar = () => {
    if (request.Bloqueado) {
      return "bg-red-300";
    } else if (request.OfertaAceptada) {
      if (isAfter(new Date(), new Date(request.OfertaAceptada.FechaTermino))) {
        return "bg-purple-400";
      } else {
        return "bg-green-300";
      }
    } else if (request.CantidadOfertas > 0) {
      return "bg-primary bg-opacity-60";
    } else if (request.CantidadOfertas === 0) {
      return "bg-yellow-500 bg-opacity-60";
    }
  };

  const badgeLeftContent = () => {
    if (request.Bloqueado) {
      return (
        <React.Fragment>
          <XIcon size={22} strokeWidth={2.5} color="white" />
        </React.Fragment>
      );
    } else if (request.OfertaAceptada) {
      if (isAfter(new Date(), new Date(request.OfertaAceptada.FechaTermino))) {
        return (
          <React.Fragment>
            <SmileIcon size={22} strokeWidth={2.5} color="white" />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <CheckIcon size={22} strokeWidth={2.5} color="white" />
          </React.Fragment>
        );
      }
    } else {
      return (
        <React.Fragment>
          <ViewIcon size={18} strokeWidth={2.5} color="white" />
          <p className="font-sm font-semibold">{request.CantidadOfertas}</p>
        </React.Fragment>
      );
    }
  };

  const validateFeatured =
    request.FechaPremium && isBefore(new Date(), request.FechaPremium.toDate());

  const handleGoToDetails = () => {
    dispatch(setSelectedRequest(request));
    navigate("/demandante/detalle/" + request.id);
  };

  const handleGoToEdit = () => {
    dispatch(setSelectedRequest(request));
    dispatch(editRequestInitial());
    navigate("/demandante/editar/" + request.id);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={
        "h-96 bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl"
      }
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
    >
      <div className="w-full h-1/2 relative">
        <img
          src={
            request.Imagenes && request.Imagenes[0]
              ? request.Imagenes[0].URL
              : "/assets/img/sin_imagen.jpg"
          }
          alt={`imagen-portada-solicitud-${request.Titulo}`}
          className="w-full h-full object-cover bg-gray-300"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0">
          <div
            className={clsx(
              "absolute left-5 top-5 h-12 w-12 rounded-full overflow-hidden bg-white"
            )}
          >
            <div
              className={clsx(
                "flex flex-col items-center justify-center text-white w-full h-full",
                backgroundColorToolBar()
              )}
            >
              {badgeLeftContent()}
            </div>
          </div>
          {!request.Bloqueado && !request.OfertaAceptada && !validateFeatured && (
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center">
              <button
                className={clsx(
                  "rounded-full w-2/4 py-1 bg-primary text-sm font-semibold text-white hover:bg-primary-dark"
                )}
                onClick={() => onFeaturedClick(request)}
              >
                Destacar Aviso
              </button>
            </div>
          )}
          {validateFeatured && (
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center">
              <div
                className={clsx(
                  "rounded-full w-2/4 py-1 bg-green-500 text-sm font-semibold text-white text-center"
                )}
              >
                Aviso Destacado
              </div>
            </div>
          )}
        </div>
      </div>
      {/* 
        Seccion inferior de la Tarjeta
      */}
      <div className="w-full h-1/2 flex flex-col justify-between">
        <h6 className="text-lg text-secondary font-bold mt-2 mx-4 ">
          {request.Titulo}
        </h6>
        <div className="mx-4">
          <p className="font-semibold text-gray-400">
            <span className="text-secondary">
              {request.Categoria}
            </span>{" "}
          </p>
          <p className="text-sm text-gray-400 flex">
            <span>
              <MapPin
                size={13}
                className="text-secondary mr-1 mt-1"
                strokeWidth={2.5}
              />
            </span>
            {request.Distancia
              ? request.Distancia >= 1000
                ? `${(request.Distancia / 1000).toFixed(1)} km`
                : `${request.Distancia} m`
              : ""}
            · {request.Region} · {request.Comuna}
          </p>
        </div>
        <div
          className={clsx(
            backgroundColorToolBar(),
            "h-12 w-full flex justify-around items-center"
          )}
        >
          <IconButton onClick={handleGoToDetails}>
            <SearchIcon
              size={20}
              className="text-secondary"
              strokeWidth={2.5}
            />
          </IconButton>
          <IconButton onClick={handleGoToEdit}>
            <EditIcon size={20} className="text-secondary" strokeWidth={2.5} />
          </IconButton>
          <IconButton onClick={openModal}>
            <DeleteIcon
              size={20}
              className="text-secondary"
              strokeWidth={2.5}
            />
          </IconButton>
        </div>
        <Modal isVisible={modal} onClose={handleOnClose} isDismiseble>
          <div className="bg-white rounded-lg h-full shadow-lg p-5 ">
            <h1 className="text-lg">
              ¿Esta seguro de eliminar esta solicitud?
            </h1>
            <div className="mt-5">
              <Button
                text="Aceptar"
                paddingClassname="py-2 px-10"
                className=" text-sm bg-primary-light"
                onClick={() => handleDelete()}
              />
              <Button
                text="Cancelar"
                paddingClassname="py-2 px-10"
                className="text-sm bg-redButton ml-5"
                onClick={handleOnClose}
              />
            </div>
          </div>
        </Modal>
      </div>
    </motion.div>
  );
};
