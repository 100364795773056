import {
  addDoc,
  doc,
  collection,
  getDocs,
  updateDoc,
  query,
  Timestamp,
  serverTimestamp,
} from "@firebase/firestore";
import { addDays, differenceInDays } from "date-fns";
import Axios from "axios";
import { orderBy } from "firebase/firestore";
import { firestore } from "../../configs/firebase";
import { AppThunk } from "../../models/app-thunk";
import { Suscription } from "../../models/Suscription";
import * as types from "../types";
import { BASE_URL } from "../../configs/constants";

export function getSuscriptions(): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.SUSCRIPTION_GET_SUBMITTING,
    });

    try {
      const suscripRef = collection(firestore, "Suscripciones");
      const q = query(suscripRef, orderBy("FechaCreacion", "desc"));
      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);

      const suscriptions = response.docs.map((x) => ({
        ...x.data(),
        Id: x.id,
      }));
      dispatch({
        type: types.SUSCRIPTION_GET_SUCCESS,
        payload: {
          suscription: suscriptions,
          lastDoc: response.docs[response.docs.length - 1],
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.SUSCRIPTION_GET_FAILURE,
        payload: error,
      });
    }
  };
}

export function suscribeOfferer(suscription: Suscription): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.SUSCRIPTION_SUSCRIBE_USER_SUBMITTING,
    });

    const user = getState().authReducer.user;
    const date = new Date();

    try {
      const docData = {
        NumeroTransaccion: date.getTime(),
        FechaTermino: Timestamp.fromDate(addDays(date, suscription.Dias)),
        FechaCreacion: Timestamp.fromDate(date),
        Suscripcion: {
          Id: suscription.Id,
          NombreSuscripcion: suscription.NombreSuscripcion,
          Dias: suscription.Dias,
          Monto: suscription.Monto,
        },
        Usuario: {
          Nombre: user.Nombre,
          Apellido: user.Apellido,
          Id: user.id,
        },
      };
      await addDoc(collection(firestore, "TransaccionesOferente"), docData);

      const token = user.Token;

      Axios.post(BASE_URL + "app//webpay_plus/create", 
        {
          suscriptionId: suscription.Id,
          amount: suscription.Monto,
          userId: user.id,
        },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` } }
      );

      const userRef = doc(firestore, "Usuarios", user.id);
      await updateDoc(userRef, {
        Premium: true,
      });

      dispatch({
        type: types.SUSCRIPTION_SUSCRIBE_USER_SUCCESS,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.SUSCRIPTION_SUSCRIBE_USER_FAILURE,
        payload: error,
      });
    }
  };
}
