import Axios from "axios";
import { format, isAfter, isBefore } from "date-fns";
import { useFormik } from "formik";
import React, {
  ButtonHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { X } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import CantidadOfertasIcon from "../../assets/icons/CantidadOfertasIcon";
import ModuloEstadisticasIcon from "../../assets/icons/ModuloEstadisticasIcon";
import OfertasDestacadasIcon from "../../assets/icons/OfertasDestacadasIcon";
import RecibeOfertasIcon from "../../assets/icons/RecibeOfertasIcon";
import { Button } from "../../components/Button";
import { IconButton } from "../../components/IconButton";
import { MenuItem } from "../../components/MenuItem";
import { Modal } from "../../components/Modal";
import { Select } from "../../components/Select";
import { auth } from "../../configs/firebase";
import { FormState } from "../../models/form_state";
import { Suscription } from "../../models/Suscription";
import { getAdvertising } from "../../redux/actions/advertising.action";
import {
  getSuscriptions,
  suscribeOfferer,
} from "../../redux/actions/suscription.action";
import {
  getMoreTransactionOfferer,
  getTransactionOfferer,
} from "../../redux/actions/transaction.action";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { AuthState } from "../../redux/reducers/auth.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { SuscriptionState } from "../../redux/reducers/suscription.reducer";
import { TransactionState } from "../../redux/reducers/transaction.reducer";
import { OffererTransactionCard } from "./components/OffererTransactionCard";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Pagination, Navigation } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "../../assets/css/swiper.css";

export const OffererTransactions = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [selected, setSelected] = useState<any>();

  const {
    suscriptions,
    add: { state: suscribeState },
  } = useSelector<RootState, SuscriptionState>(
    (state) => state.suscriptionReducer
  );

  const { handleSubmit, values, touched, setFieldValue, errors } = useFormik({
    initialValues: {
      TipoSuscripcion: {
        Monto: 0,
      } as Suscription,
    },
    onSubmit: (values) => {
      //dispatch(suscribeOfferer(values.TipoSuscripcion));
    },
    validationSchema: yup.object({
      TipoSuscripcion: yup
        .object()
        .required("Debe seleccionar una suscripción"),
    }),
  });
  const navigate = useNavigate();
  const [openPayModal, setOpenPayModal] = useState(false);

  const divFormRef = useRef<HTMLDivElement | null>(null);

  const formFormRef = useRef<HTMLFormElement | null>(null);
  const inputTokenFormRef = useRef<HTMLInputElement | null>(null);
  const buttonFormRef = useRef<HTMLButtonElement | null>(null);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const handleOnClosePayModal = async () => {
    setIsLoadingPayment(true);
    try {
      const token = user?.Token;

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,  // Adjunta el token en el header
          'Content-Type': 'application/json'   // Define el tipo de contenido
        }
      };

      const response = await Axios.post(
        "https://us-central1-demandate-77297.cloudfunctions.net/app/webpay_plus/create",
        {
          buyId: values.TipoSuscripcion.Id,
          amount: parseInt(values.TipoSuscripcion.Monto as any),
          userId: auth.currentUser?.uid,
        },
        config
      );
      if (!formFormRef.current) return;

      if (!inputTokenFormRef.current) return;

      if (!buttonFormRef.current) return;
      formFormRef.current.action = response.data.url;
      inputTokenFormRef.current.value = response.data.token;
      buttonFormRef.current.click();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPayment(false);
      setOpenPayModal(false);
    }
  };

  const handleOpenPayModal = () => {
    setOpenPayModal(true);
  };

  const { offererTransactions } = useSelector<RootState, TransactionState>(
    (state) => state.transactionReducer
  );

  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );

  const moreTransactions = (total: any) => {
    dispatch(getMoreTransactionOfferer(total));
  };

  useEffect(() => {
    if (user) {
      dispatch(getTransactionOfferer());
    }
    // eslint-disable-next-line
  }, [suscribeState, user]);

  useEffect(() => {
    dispatch(getSuscriptions());
    // eslint-disable-next-line
  }, []);

  const handleSeeDetails = (transaction: any) => {
    setSelected(transaction);
    setOpen(true);
  };

  const handleOnClose = () => {
    setOpen(false);
  };
  const todayDate = new Date();
  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Suscripciones" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Suscripciones" && x.Posicion === "inferior"
  );

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  let styleSeleccion =
    "bg-primary-100   rounded-lg flex flex-col justify-center items-center text-secondary  px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl";

  const [seleccionado, setseleccionado] = useState(-1);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);
  return (
    <main className=" px-8 lg:container lg:mx-auto">
      {offererTransactions.state === FormState.Failure && (
        <p>{offererTransactions.error}</p>
      )}
      <Helmet title="Mis Transacciones" />
      <div className="mt-10 lg:mt-0 md:mt-0">
        <h1 className=" text-3xl font-bold text-deepBlue ">Suscripciones</h1>
        {AdvertisingSup.length === 1 && (
          <a href={AdvertisingSup[0].Link} target={"_blank"}>
            {actualWidth > 400 ? (
              <img
                src={AdvertisingSup[0].Imagen.URL}
                className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
                style={{ width: 1309, height: 150 }}
              ></img>
            ) : (
              <img
                src={AdvertisingSup[0].ImagenMovil.URL}
                className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
                style={{ width: 1309, height: 150 }}
              ></img>
            )}
          </a>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 gap-3 mt-5">
          {user?.FechaPremium !== null &&
          isAfter(user?.FechaPremium.toDate(), new Date()) ? (
            <div
              onClick={() => navigate("/oferente/estadisticas")}
              className="bg-primary-100 bg-opacity-60 rounded-lg flex flex-col justify-center items-center text-secondary h-48 px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl"
            >
              {<ModuloEstadisticasIcon height={110} width={110} />}
              <button
                className="text-sm font-bold text-center"
                onClick={() => navigate("/oferente/estadisticas")}
              >
                {"Módulo de estadísticas"}
              </button>
            </div>
          ) : (
            <div className="bg-primary-100 bg-opacity-60 rounded-lg flex flex-col justify-center items-center text-secondary h-48 px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl">
              {<ModuloEstadisticasIcon height={110} width={110} />}
              <span className="text-sm font-bold text-center">
                {"Módulo de estadísticas"}
              </span>
            </div>
          )}

          {user?.FechaPremium !== null &&
          isAfter(user?.FechaPremium.toDate(), new Date()) ? (
            <div
              onClick={() => navigate("/oferente/alertas")}
              className="bg-primary-100 bg-opacity-60 rounded-lg flex flex-col justify-center items-center text-secondary h-48 px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl"
            >
              {<RecibeOfertasIcon height={110} width={110} />}
              <button
                className="text-sm font-bold text-center"
                onClick={() => navigate("/oferente/alertas")}
              >
                {"Módulo de alertas"}
              </button>
            </div>
          ) : (
            <div className="bg-primary-100 bg-opacity-60 rounded-lg flex flex-col justify-center items-center text-secondary h-48 px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl">
              {<RecibeOfertasIcon height={110} width={110} />}
              <span className="text-sm font-bold text-center">
                {"Módulo de alertas"}
              </span>
            </div>
          )}

          <div className="bg-primary-100 bg-opacity-60 rounded-lg flex flex-col justify-center items-center text-secondary h-48 px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl">
            {<CantidadOfertasIcon height={110} width={110} />}
            <span className="text-sm font-bold text-center">
              {"Cantidad de ofertas de una \ndemanda"}
            </span>
          </div>

          <div className="bg-primary-100 bg-opacity-60 rounded-lg flex flex-col justify-center items-center text-secondary h-48 px-5 cursor-pointer shadow-md overflow-hidden hover:shadow-xl">
            {<OfertasDestacadasIcon height={110} width={110} />}
            <span className="text-sm font-bold text-center">
              {"Ofertas destacadas"}
            </span>
          </div>
        </div>
      </div>

      <div className="my-16 h-36 ">
        <h3 className=" text-xl font-bold text-deepBlue mt-3 mb-6  ">
          Suscripciones
        </h3>
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          slidesPerGroup={3}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {suscriptions.map((suscription, index) => (
            <SwiperSlide
              key={index}
              onClick={() => {
                setseleccionado(index);
                setFieldValue("TipoSuscripcion", suscription);
              }}
              className={
                seleccionado === index
                  ? `${styleSeleccion} h-24 border-4 border-blue-400 mb-11	 `
                  : `${styleSeleccion} h-20 bg-opacity-60 border-4 border-blue-200 `
              }
            >
              <span className="text-base font-bold text-center">
                {`${suscription.NombreSuscripcion} `}
                <p>{`${suscription.Dias} Días`}</p>
              </span>
            </SwiperSlide>
          ))}

          {/*      

{arreglo.map((x, index) => (
            <div
              key={index}
              onClick={() => {setseleccionado(index)}}
                
              className={seleccionado ===index ? ("h-32 w-32 p-4 border-4 bg-red-700"):("h-32 w-32 p-4 border-4 bg-blue-700")}
            >
              {x}
            </div>
          ))} */}
        </Swiper>
      </div>

      <div>
        <div className="place-content-center w-full flex flex-col sm:flex-row lg:flex-row mt-2 ">
          {values.TipoSuscripcion.Monto != 0 && (
            <Button
              text={`Suscribirse: $${values.TipoSuscripcion.Monto}`}
              className="bg-primary mt-7 ml-0 sm:ml-10 md:ml-10 lg:ml-10 w-72 "
              onClick={handleOpenPayModal}
              disabled={true}
            />
          )}

          <form method="POST" style={{ display: "none" }} ref={formFormRef}>
            <input ref={inputTokenFormRef} type="hidden" name="token_ws" />
            <button ref={buttonFormRef} type="submit" value="Pagar" />
          </form>
          <Modal
            isVisible={openPayModal}
            onClose={handleOnClosePayModal}
            isDismiseble
          >
            <div className="bg-white rounded-lg w-auto shadow-lg pb-5">
              <div className="flex flex-row-reverse">
                <IconButton>
                  <X size={32} className="text-secondary" />
                </IconButton>
              </div>
              <div className="flex flex-col items-center ">
                <h1 className="font-bold text-2xl text-deepBlue">
                  {`${values.TipoSuscripcion.NombreSuscripcion}`}
                </h1>
                <h2 className="font-semibold text-primary text-lg">
                  {`Obtén tu membresía por ${values.TipoSuscripcion.Dias} días.`}
                </h2>

                <p className="font-bold text-4xl mt-4 text-deepBlue">{`$${values.TipoSuscripcion.Monto}`}</p>
                <img
                  src="/assets/img/logo-web-pay-plus.png"
                  alt="imagen-webpay"
                  className="w-2/4"
                />
                <Button
                  type="button"
                  text="Ir a pagar"
                  className="bg-primary w-2/4 mt-6"
                  onClick={handleOnClosePayModal}
                  isLoading={isLoadingPayment}
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>

      {/* <div className="flex flex-wrap justify-center text-align-center mt-8 mx-auto"></div> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8 mr-5">
        {offererTransactions.transactions &&
          offererTransactions.state === FormState.Success &&
          offererTransactions.transactions.map((transaction, index) => (
            <OffererTransactionCard
              key={`transaction-card-${index}`}
              transaction={transaction}
              onSeeDetails={handleSeeDetails}
            />
          ))}
      </div>
      <Modal isVisible={open} onClose={handleOnClose} isDismiseble>
        <div className="bg-white rounded-lg h-full shadow-lg pl-5 pb-5">
          <div className="flex flex-col items-left mb-3 mr-3">
            <IconButton className="ml-auto" onClick={handleOnClose}>
              <X size={32} className="text-secondary" />
            </IconButton>
            {selected && (
              <React.Fragment>
                <div className="font-bold text-xl text-blue-400 mb-2">{`Aviso #${selected.Suscription.Id}`}</div>
                {isAfter(todayDate, selected.FechaPremium.toDate()) ? (
                  <h3 className="font-bold text-sm text-red-600 mb-2">
                    {`Vigencia: ${format(
                      selected.FechaCreacion.toDate(),
                      "dd/MM/yyyy"
                    )} - ${format(
                      selected.FechaPremium.toDate(),
                      "dd/MM/yyyy"
                    )}`}
                  </h3>
                ) : (
                  <h3 className="font-bold text-sm text-greenDem mb-2">
                    {`Vigencia: ${format(
                      selected.FechaCreacion.toDate(),
                      "dd/MM/yyyy"
                    )} - ${format(
                      selected.FechaPremium.toDate(),
                      "dd/MM/yyyy"
                    )}`}
                  </h3>
                )}
                <div className="font-bold text-sm text-gray-400 mb-2">
                  {`Fecha de pago: ${format(
                    selected.FechaCreacion.toDate(),
                    "dd/MM/yyyy"
                  )}`}
                </div>
                <div className="text-sm font-bold text-gray-400 mb-2">{`Monto: $${selected.Suscription.Precio}`}</div>
                <div className="text-sm font-bold text-gray-400">{`Transacción: ${selected.Transbank.buy_order}`}</div>
              </React.Fragment>
            )}
          </div>
        </div>
      </Modal>
      <div className="mb-10 flex items-center justify-center ">
        <Button
          type="submit"
          text="Cargar más transacciones"
          color="secondary"
          className="mt-10  flex items-center justify-center "
          onClick={() => moreTransactions(offererTransactions.totalDocsOfferes)}
        />
      </div>
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 mb-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 mb-5 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
    </main>
  );
};
