import clsx from "clsx";
import React from "react";
import { MapPin } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Modal } from "../../../components/Modal";
import { PlaintiffRequest } from "../../../models/PlaintiffRequest";
import {
  isOffered,
  setSelectedRequest,
} from "../../../redux/actions/requests.actions";
import { RequestsState } from "../../../redux/reducers/requests.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";

interface Props {
  request: PlaintiffRequest;
  open: boolean;
  handleOnClose: () => void;
}

export const MapRequestCard: React.FC<Props> = ({
  request,
  open,
  handleOnClose,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requests } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  );

  //(isBefore(request.Destacada?.FechaTermino.toDate(), new Date()))

  const userId = request.Usuario.Id;
  const userUbiLat = request.Ubicacion.lat;
  const userUbiLng = request.Ubicacion.lng;

  let requestSameUser: PlaintiffRequest[] = [];

  requests.map(
    (request) =>
      request.Usuario.Id === userId &&
      request.Ubicacion.lat === userUbiLat &&
      request.Ubicacion.lng === userUbiLng &&
      requestSameUser.push(request)
  );

  const handleGoToDetails = () => {
    dispatch(setSelectedRequest(request));
    dispatch(isOffered(request.id));
    navigate("/solicitud/" + request.id + "/detalle");
  };

  return (
    <Modal isVisible={open} onClose={handleOnClose} isDismiseble>
      {requestSameUser.length === 1 ? (
        <div className="carousel-inner bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl h-auto w-72 pb-5">
          <div className="w-full relative">
            <img
              src={
                request.Imagenes && request.Imagenes[0]
                  ? request.Imagenes[0].URL
                  : "/assets/img/sin_imagen.jpg"
              }
              alt={`imagen-portada-solicitud-${request.Titulo}`}
              className="w-full h-40 object-cover bg-gray-300"
              onClick={handleGoToDetails}
            />
            <div className="absolute top-0 left-0 right-0 bottom-0">
              <div
                className={clsx(
                  "flex flex-col items-center justify-center text-white w-full h-full"
                )}
              ></div>
            </div>
          </div>
          {/* 
    Seccion inferior de la Tarjeta
  */}
          <div className="w-full flex flex-col justify-between bg-white">
            <h6
              className="text-lg text-secondary font-bold mt-2 mx-4 pointer-events-auto"
              onClick={handleGoToDetails}
            >
              {request.Titulo}
            </h6>
            <div className="mx-4">
              <p className="font-semibold text-gray-400">
                {request.Categoria}
              </p>
              <p className="text-sm text-gray-400 flex">
                <span>
                  <MapPin
                    size={13}
                    className="text-secondary mr-1 mt-1"
                    strokeWidth={2.5}
                  />
                </span>
                {request.Distancia
                  ? request.Distancia >= 1000
                    ? `${(request.Distancia / 1000).toFixed(1)} km`
                    : `${request.Distancia} m`
                  : ""}
                · {request.Region} · {request.Comuna}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
          <Swiper
            pagination
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation]}
            className="h-auto w-72 rounded-xl"
          >
            {requestSameUser.map((request) => (
              <SwiperSlide>
                <div className="bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl h-auto w-72 pb-5">
                  
                   {/*SECCION IMAGEN DE LA TARJETA*/}
                  <div className="w-full h-60 ">
                    <img
                      src={
                        request.Imagenes && request.Imagenes[0]
                          ? request.Imagenes[0].URL
                          : "/assets/img/sin_imagen.jpg"
                      }
                      alt={`imagen-portada-solicitud-${request.Titulo}`}
                      className="w-full h-40 object-cover bg-gray-300"
                      onClick={handleGoToDetails}
                    />
                    <div className="absolute top-0 left-0 right-0 bottom-0">
                      <div
                        className={clsx(
                          "flex flex-col items-center justify-center text-white w-full h-full"
                        )}
                      ></div>
                    </div>
                  </div>
                   {/*FIN IMAGEN DE LA TARJETA*/}

                  {/*SECCION INFERIOR DE LA TARJETA*/}



                  <div className="w-full flex flex-col justify-between bg-white">
                    <h6
                      className="text-lg text-secondary font-bold mt-2 mx-4 pointer-events-auto"
                      onClick={handleGoToDetails}
                    >
                      {request.Titulo}
                    </h6>
                    <div className="mx-4">
                      <p className="font-semibold text-gray-400">
                        <span className="text-secondary">
                          {request.Categoria}
                        </span>{" "}
                      </p>
                      <p className="text-sm text-gray-400 flex">
                        Deslice hacia los lados para ver todas las solicitudes
                        de esta ubicación
                      </p>
                    
                    </div>

                    
                  </div>

                  {/*FIN SECCION INFERIOR DE LA TARJETA*/}

                </div>


              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </Modal>
  );
};
