import clsx from "clsx";
import { isAfter, isBefore } from "date-fns";
import { motion } from "framer-motion";
import React from "react";
import { Bell, MapPin, Search as SearchIcon, Star } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../components/IconButton";
import { FormState } from "../../../models/form_state";
import { PlaintiffRequest } from "../../../models/PlaintiffRequest";
import {
  isOffered,
  setSelectedRequest,
} from "../../../redux/actions/requests.actions";
import { AuthState } from "../../../redux/reducers/auth.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";

import '../../../assets/css/offerRequest.css';

interface Props {
  request: PlaintiffRequest;
  onFeaturedClick: (request: PlaintiffRequest) => void;
}

export const OffererRequestCard: React.FC<Props> = ({
  request,
  onFeaturedClick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, state: UserState } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );

  const backgroundColorToolBar = () => {
    
      if(request.FechaPremium === null || isBefore(request.FechaPremium.toDate(), new Date())){
        return "bg-primary-100";
      } else if(isBefore(new Date(), request.FechaPremium.toDate())) {
      return "bg-green-300";
      }
    
  };

  const badgeLeftContent = () => {
    if (!request.Bloqueado) {
      return (
        <React.Fragment>
          <Bell size={18} strokeWidth={2.5} color="white" />
          <p className="font-sm font-semibold">{request.CantidadOfertas}</p>
        </React.Fragment>
      );
    }
  };

  const validateFeatured = () => {
    if (isBefore(new Date(), request.FechaPremium?.toDate())) {
      return true;
    } else{
      return false;
    }
  };

  const handleGoToDetails = () => {
    dispatch(setSelectedRequest(request));
    dispatch(isOffered(request.id));
    navigate("/solicitud/" + request.id + "/detalle");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={
        "h-96 bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl"
      }
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
    >
      <div className="w-full h-1/2 relative">
        <img
          src={
            request.Imagenes && request.Imagenes[0]
              ? request.Imagenes[0].URL
              : "/assets/img/sin_imagen.jpg"
          }
          alt={`imagen-portada-solicitud-${request.Titulo}`}
          className="w-full h-full object-cover bg-gray-300"
        />
        <div className="absolute top-0 left-0 right-0 bottom-0">
          {isBefore(new Date(), user?.FechaPremium?.toDate()) && UserState === FormState.Success && (
            <div
              className={clsx(
                "absolute left-5 top-5 h-12 w-12 rounded-full overflow-hidden bg-white"
              )}
            >
              <div
                className={clsx(
                  "flex flex-col items-center justify-center text-white w-full h-full",
                  backgroundColorToolBar()
                )}
              >
                {badgeLeftContent()}
              </div>
            </div>
          )}
          { validateFeatured() &&
            isBefore( new Date(), request.FechaPremium?.toDate()) && (
              <div className="absolute top-60 left-0 right-0 flex items-center justify-center">
                <div
                  className={clsx(
                    "rounded-full w-2/4 py-1 bg-greenDem text-sm font-semibold text-white"
                  )}
                >
                  <div className="flex flex-row">
                    <Star className="ml-3 mt-0.5" size={20} />
                    <span className="ml-2 text-base">Destacado</span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {/* 
        Seccion inferior de la Tarjeta
      */}
      <div className="w-full h-1/2 flex flex-col justify-between">
        <h6 className="text-lg text-secondary font-bold mt-4 mx-4 title-offer">
          {request.Titulo}
        </h6>
        <div className="mx-4">
          <p className="font-semibold text-gray-400">
            <span className="text-secondary">
              {request.Categoria}
            </span>{" "}
          </p>
          <p className="text-sm text-gray-400 flex">
            <span>
              <MapPin
                size={13}
                className="text-secondary mr-1 mt-1"
                strokeWidth={2.5}
              />
            </span>
            {request.Distancia
              ? request.Distancia >= 1000
                ? `${(request.Distancia / 1000).toFixed(1)} km`
                : `${request.Distancia} m`
              : ""}
            · {request.Region} · {request.Comuna}
          </p>
        </div>
        <div
          className={clsx(
            backgroundColorToolBar(),
            "h-12 w-full flex justify-around items-center"
          )}
        >
          <IconButton onClick={handleGoToDetails}>
            <SearchIcon
              size={20}
              className="text-secondary"
              strokeWidth={2.5}
            />
          </IconButton>
        </div>
      </div>
    </motion.div>
  );
};
