import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
} from "@firebase/firestore";
import Axios from "axios";
import { isBefore } from "date-fns";
import { orderBy } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { BASE_URL } from "../../configs/constants";
import { firestore, storage } from "../../configs/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";
import { getDocsByIds, getMultipleDocsByIds } from "../../utils/utils";
import * as types from "../types";
import { setSelectedRequest } from "./requests.actions";

export function getOffer(id: string): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.OFFER_GET_SUBMITTING,
    });

    try {
      const collRef = collection(firestore, "Solicitudes", id, "Ofertas");

      const q = query(
        collRef,
        where("Eliminada", "==", false),
        orderBy("FechaCreacion", "desc")
      );

      const offersSnaps = await getDocs(q);

      const usersSet = new Set<string>();
      offersSnaps.docs.forEach((x) => usersSet.add(x.data().Usuario.Id));

      const usersOfOffers = await getMultipleDocsByIds(usersSet, "Usuarios");

      const offers = offersSnaps.docs
        .map((x) => {
          const user = usersOfOffers.find((u) => u.id === x.data().Usuario.Id);
          if (!user) throw new Error("Fallo al obtener el usuario");

          const data = {
            ...(x.data() as PlaintiffOffer),
            id: x.id,
            Usuario: {
              ...x.data().Usuario,
              Calificacion: user?.Calificacion || 0,
              FechaPremium: user?.FechaPremium || null,
            },
          };
          return data;
        })
        .sort((a, b) => {
          if (a.Usuario?.FechaPremium && !b.Usuario.FechaPremium) {
            return -1;
          } else if (a.Usuario?.FechaPremium && b.Usuario?.FechaPremium) {
            return (
              b.Usuario?.FechaPremium.getTime() -
              a.Usuario?.FechaPremium.getTime()
            );
          } else {
            return 0;
          }
        });

      dispatch({
        type: types.OFFER_GET_SUCCESS,
        payload: {
          lastDoc: offersSnaps.docs[offersSnaps.docs.length - 1],
          offers,
          totalDocs: offersSnaps.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.OFFER_GET_FAILURE,
        payload: error,
      });
    }
  };
}

export function getMyOffers(): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_GET_SUBMITTING,
    });
    const user = getState().authReducer.user;

    try {
      const userOffers = query(
        collectionGroup(firestore, "Ofertas"),
        where("Eliminada", "==", false),
        where("Archivada", "==", false),
        where("Usuario.Id", "==", user?.id),
        limit(10)
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(userOffers),
        getDocs(userOffers),
      ]);

      const solicitudesId = new Set<string>();

      response.docs.forEach((x) => {
        x.ref.parent.parent && solicitudesId.add(x.ref.parent.parent.id);
      });

      const solicitudes = await getDocsByIds(solicitudesId, "Solicitudes");

      const offers = response.docs.map((x) => {
        return {
          ...x.data(),
          solicitudData: solicitudes.find(
            (soli) => x.ref.parent.parent?.id === soli.id
          ),
          id: x.id,
          requestRef: x.ref.parent.parent?.id,
          Usuario: {
            Nombre: user.Nombre,
            Apellido: user.Apellido,
            Direccion: user.Direccion,
            Comuna: user.Comuna,
            Region: user.Region,
            Email: user.Email,
            Telefono: user.Telefono,
            Id: user.id,
          },
        };
      });

      dispatch({
        type: types.OFFER_GET_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          offers,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REGISTER_OFFERER_FAILURE,
        payload: error,
      });
    }
  };
}

export function getMoreOffers(total: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_GET_SUBMITTING,
    });
    const user = getState().authReducer.user;

    try {
      const userOffers = query(
        collectionGroup(firestore, "Ofertas"),
        where("Eliminada", "==", false),
        where("Archivada", "==", false),
        where("Usuario.Id", "==", user?.id),
        limit(total + 10)
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(userOffers),
        getDocs(userOffers),
      ]);

      const solicitudesId = new Set<string>();

      response.docs.forEach((x) => {
        x.ref.parent.parent && solicitudesId.add(x.ref.parent.parent.id);
      });

      const solicitudes = await getDocsByIds(solicitudesId, "Solicitudes");

      const offers = response.docs.map((x) => {
        return {
          ...x.data(),
          solicitudData: solicitudes.find(
            (soli) => x.ref.parent.parent?.id === soli.id
          ),
          id: x.id,
          requestRef: x.ref.parent.parent?.id,
          Usuario: {
            Nombre: user.Nombre,
            Apellido: user.Apellido,
            Direccion: user.Direccion,
            Comuna: user.Comuna,
            Region: user.Region,
            Email: user.Email,
            Telefono: user.Telefono,
            Id: user.id,
          },
        };
      });

      dispatch({
        type: types.OFFER_GET_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          offers,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REGISTER_OFFERER_FAILURE,
        payload: error,
      });
    }
  };
}

export function addOffer(offer: any, requestId: string): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_ADD_SUBMITTING,
    });

    const user = getState().authReducer.user;

    try {
      const requestRef = doc(firestore, "Solicitudes", requestId);
      await updateDoc(requestRef, { CantidadOfertas: increment(1) });

      const docData = {
        ...offer,
        Archivada: false,
        Deshabilitado: false,
        Eliminada: false,
        Calificado: false,
        Aceptada: false,
        FechaCreacion: serverTimestamp(),
        Usuario: {
          Nombre: user.Nombre,
          Apellido: user.Apellido,
          Id: user.id,
          Region: user.Region,
          Comuna: user.Comuna,
          Calificacion: user.Calificacion,
          Archivos: user.Archivos ? user.Archivos : [],
        },
      };
      //delete docData.Imagenes;

      const docRef = await addDoc(
        collection(firestore, "Solicitudes", requestId, "Ofertas"),
        docData
      );

      const catRef = collection(firestore, "Categorias");
      const q = query(catRef, where("Nombre", "==", docData.Categoria));
      const response = await getDocs(q);
      const cateId = response.docs.map((x: any) => ({ id: x.id }));
      const catContRef = doc(firestore, "Categorias", cateId[0].id);
      await updateDoc(catContRef, { CantidadOfertas: increment(1) });
      const token = user.Token;
      Axios.post(
        BASE_URL + "app/oferta/create",
        {
          requestId: requestId,
          userOfferId: user.id,
        },
        { headers: 
          { 
            "Content-Type": "application/json", 
            "Authorization": `Bearer ${token}`
          } 
        }
      );

      dispatch({
        type: types.OFFER_ADD_SUCCESS,
      });
    } catch (error: any) {
      dispatch({
        type: types.OFFER_ADD_FAILURE,
        payload: error,
      });
    }
  };
}

export const offerAddInitial = (): Action => ({
  type: types.OFFER_ADD_INITIAL,
});

export const setSelectedOffer = (offer: any): Action => ({
  type: types.OFFER_GET_ONE_SUCCESS,
  payload: offer,
});

export const setSelectedOfferInitial = (): Action => ({
  type: types.OFFER_GET_ONE_INITIAL,
});

export function editOffer(offer: any, request_Id: string): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_EDIT_SUBMITTING,
    });

    const user = getState().authReducer.user;

    try {
      const offerRef = doc(
        firestore,
        "Solicitudes",
        request_Id,
        "Ofertas",
        offer.id
      );
      //const prevOfferSnap = (await getDoc(offerRef)).data()
      await updateDoc(offerRef, {
        Titulo: offer.Titulo,
        Precio: offer.Precio,
        Cantidad: {
          Cantidad: offer.Cantidad.Cantidad,
          Tipo: offer.Cantidad.Tipo,
        },
        FechaInicio: offer.FechaInicio,
        FechaTermino: offer.FechaTermino,
        FechaActualizacion: serverTimestamp(),
        Mensaje: offer.Mensaje,
      });
      const token = user.Token;
      await Axios.post(
        BASE_URL + "app/oferta/update",
        {
          requestId: request_Id,
          deleteOrUpdate: false,
          userOfferId: user.id,
        },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` } }
      );

      /* if (offer.Categoria !== prevOfferSnap?.Categoria) {
        const catRef = collection(firestore, "Categorias");
        const q = query(catRef, where("Nombre", "==", offer.Categoria));
        const response = await getDocs(q);
        const cateRef = response.docs[0].ref;
        await updateDoc(cateRef, { CantidadOfertas: increment(1) })
        const queryNew = query(
          catRef,
          where("Nombre", "==", prevOfferSnap?.Categoria)
        );
        const newResponse = await getDocs(queryNew);
        const cateNewRef = newResponse.docs[0].ref;
        await updateDoc(cateNewRef, { CantidadOfertas: increment(-1) })
      } */
      dispatch({
        type: types.OFFER_EDIT_SUCCESS,
        payload: {
          ...offer,
        },
      });
    } catch (error: any) {
      dispatch({
        type: types.OFFER_EDIT_FAILURE,
        payload: error,
      });
    }
  };
}

export const editOfferInitial = (): Action => ({
  type: types.OFFER_EDIT_INITIAL,
});

export function deleteOffer(offer: any, requestId: string): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_DELETE_SUBMITTING,
    });
    const user = getState().authReducer.user;
    try {
      const requestRef = doc(firestore, "Solicitudes", requestId);
      await updateDoc(requestRef, { CantidadOfertas: increment(-1) });

      const docRef = doc(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas",
        offer.id
      );
      await updateDoc(docRef, { Eliminada: true });

      const catRef = collection(firestore, "Categorias");
      const q = query(catRef, where("Nombre", "==", offer.Categoria));
      const response = await getDocs(q);
      const cateId = response.docs.map((x: any) => ({ id: x.id }));
      const catContRef = doc(firestore, "Categorias", cateId[0].id);
      await updateDoc(catContRef, { CantidadOfertas: increment(-1) });
      const token = user.Token;
      await Axios.post(
        BASE_URL + "app/oferta/update",
        {
          requestId: requestId,
          deleteOrUpdate: true,
          userOfferId: user.id,
        },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` } }
      );

      dispatch({
        type: types.OFFER_DELETE_SUCCESS,
        payload: offer,
      });
    } catch (error: any) {
      dispatch({
        type: types.OFFER_DELETE_FAILURE,
        payload: error,
      });
    }
  };
}

export const deleteOfferInitial = (): Action => ({
  type: types.OFFER_DELETE_INITIAL,
});

export function getOneOffer(requestId: string, offerId: string): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_GET_ONE_SUBMMITING,
    });
    // eslint-disable-next-line
    const user = getState().authReducer.user;

    try {
      const docRef = doc(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas",
        offerId
      );

      const response = await getDoc(docRef);
      const offer: any = { ...response.data(), id: response.id };

      dispatch({
        type: types.OFFER_GET_ONE_SUCCESS,
        offer,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.OFFER_GET_ONE_FAILURE,
        payload: error,
      });
    }
  };
}

export function getOneOfferAndRequest(
  requestId: string,
  offerId: string
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.OFFER_GET_ONE_SUBMMITING,
    });
    // eslint-disable-next-line
    const user = getState().authReducer.user;

    try {
      const offerRef = doc(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas",
        offerId
      );
      const requestRef = doc(firestore, "Solicitudes", requestId);

      const [offerResponse, requestResponse] = await Promise.all([
        getDoc(offerRef),
        getDoc(requestRef),
      ]);
      const offer: any = { ...offerResponse.data(), id: offerResponse.id };

      const request: any = {
        ...requestResponse.data(),
        id: requestResponse.id,
      };

      // se deberia validar si mi solicitud es la elegida

      if (request.OfertaAceptada) {
        if (offer.id === request.OfertaAceptada.OfertaId) {
          const userResponse = await getDoc(
            doc(firestore, "Usuarios", request.Usuario.Id)
          );
          const user: any = {
            ...userResponse.data(),
            Id: userResponse.id,
          };

          request.Usuario = user;
        }
      }
      dispatch({
        type: types.OFFER_GET_ONE_SUCCESS,
        payload: offer,
      });

      dispatch(setSelectedRequest(request));
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.OFFER_GET_ONE_FAILURE,
        payload: error,
      });
    }
  };
}

export function addReporte(motivo: any, requestId: any): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REPORTE_ADD_SUBMITTING,
    });

    const user = getState().authReducer.user;
    try {
      const { value: razon } = motivo.Reportes;
      
      const userData = {
        Nombre: user ? user.Nombre : "Usuario No Registrado",
        Apellido: user  ? user.Apellido : "Usuario No Registrado",
        Id: user ? user.id : "Usuario No Registrado",
      }
      const docData = {
        /* ...motivo, */
        Reporte: razon,
        FechaCreacion: serverTimestamp(),
        Usuario: userData,
        idSolicitud: requestId,
      };
      await addDoc(collection(firestore, "Reportes"), docData);

      const requestRef = doc(firestore, "Solicitudes", requestId);
      await updateDoc(requestRef, { CantidadReportes: increment(1) });

      dispatch({
        type: types.REPORTE_ADD_SUCCESS,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REPORTE_ADD_FAILURE,
        payload: error,
      });
    }
  };
}
