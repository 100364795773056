import { useFormik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CrossIcon from "../assets/icons/CrossIcon";
import { Button } from "../components/Button";
import { TextField } from "../components/TextField";
import { categories } from "../configs/categories";
import { regions } from "../configs/regions";
import { FormState } from "../models/form_state";
import { getRequestFiltered, getRequests } from "../redux/actions/requests.actions";
import { RequestsState } from "../redux/reducers/requests.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { CustomSelect } from "./CustomSelect";
import { IconButton } from "./IconButton";
import { MenuItem } from "./MenuItem";
import { Select } from "./Select";
import regionesComunas from "../assets/RegionesComunas.json";
import { getCategories } from "../redux/actions/categories.action";
import { CategoriesState } from "../redux/reducers/categories.reducer";
import { communes } from "../configs/communes";


interface Props {
  isVisible: boolean;
  onClose: VoidFunction;
  title?: string;
}

export const FilterMenu: React.FC<Props> = ({ onClose, isVisible }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories())
  }, [])
  
  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );
  const { state: RequestsState } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  );
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      setTimeout(() => {
        onClose();
      }, 50);
    }
  };

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        filterBy: "",
        search: "",
        FechaInicio: "",
        FechaTermino: "",
        PrecioMin: 0,
        PrecioMax: 0
      },
      onSubmit: () => {
        //["Region", "Comuna", "Categoria", "Fecha", "Precio"]
        if (values.filterBy) {
          dispatch(getRequestFiltered(values));
        }
      },
    });

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter={true}
      onExitComplete={() => null}
    >
      {isVisible && (
        <motion.div
          ref={ref}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="bg-primary pt-5 pb-10 px-6 flex flex-col items-center z-30 absolute left-0 top-12"
          style={{
            width: 500,
            marginLeft: 0,
            marginTop: 10,
            maxWidth: "100vw",
            height: 400,
          }}
        >
          <div className="flex flex-col">
            <IconButton onClick={onClose} className="absolute right-6">
              <CrossIcon className="w-4 h-4" />
            </IconButton>
            <form onSubmit={handleSubmit}>
              <h4 className="text-lg font-bold text-white text-center">
                Filtrar Por:  
              </h4>
              <Select
                id="filterBy"
                placeholder="Filtrar por..."
                divClassName="mt-5 mb-0"
                value={values.filterBy}
                helperText={touched?.filterBy && errors?.filterBy}
                error={touched?.filterBy && Boolean(errors?.filterBy)}
                onChange={(value) => {
                  setFieldValue("filterBy", value);
                }}
              >
                {["Region", "Comuna", "Categoria", "Fecha", "Archivadas"].map(
                  (Filtro, index) => (
                    <MenuItem
                      key={`Filtro-${index}`}
                      value={Filtro}
                      label={Filtro}
                    />
                  )
                )}
              </Select>
              <div>
                {values.filterBy === "Fecha" && (
                  <div className="flex flex-row">
                    <TextField
                      id="FechaInicio"
                      label="Fecha inicial"
                      type="date"
                      divClassName="mt-4 w-3/5"
                      value={values.FechaInicio}
                      onChange={handleChange}
                      helperText={touched.FechaInicio && errors.FechaInicio}
                      error={touched.FechaInicio && Boolean(errors.FechaInicio)}
                    />
                    <TextField
                      id="FechaTermino"
                      label="Fecha termino"
                      type="date"
                      divClassName="mt-4 w-3/5 ml-3"
                      value={values.FechaTermino}
                      onChange={handleChange}
                      helperText={touched.FechaTermino && errors.FechaTermino}
                      error={
                        touched.FechaTermino && Boolean(errors.FechaTermino)
                      }
                    />
                  </div>
                )}
                {values.filterBy === "Precio" && (
                  <div className="flex flex-row">
                    <TextField
                      type="number"
                      label="Precio Minimo"
                      id="PrecioMin"
                      divClassName="mt-3 w-3/5"
                      placeholder="Minimo"
                      value={values.PrecioMin}
                      onChange={handleChange}
                      helperText={touched.PrecioMin && errors.PrecioMin}
                      error={touched.PrecioMin && Boolean(errors.PrecioMin)}
                    />
                    <TextField
                      type="number"
                      label="Precio Maximo"
                      id="PrecioMax"
                      divClassName="mt-3 w-3/5 ml-3"
                      placeholder="Maximo"
                      value={values.PrecioMax}
                      onChange={handleChange}
                      helperText={touched.PrecioMax && errors.PrecioMax}
                      error={touched.PrecioMax && Boolean(errors.PrecioMax)}
                    />
                  </div>
                )}
                {values.filterBy === "Region" && (
                   <CustomSelect
                   id="Region"
                   label="Región"
                   placeholder="Seleccionar Región"
                   options={regionesComunas.regiones.map((region) => ({
                     label: region.region,
                     value: region.region,
                   }))}
                   value={values.search}
                   isSearchable
                   onChange={(value: any) => {
                     setFieldValue("search", value);
                   }}
                 />
                )}
                {values.filterBy === "Categoria" && (
                  <CustomSelect
                  label="Categoria"
                    id="Categoria"
                    options={categories.map((categorie) => ({
                      label: categorie.Nombre,
                      value: categorie.Nombre,
                    }))}
                    placeholder="Selecciona categoria"
                    onChange={(value: any) => {
                      setFieldValue("search", value);
                    }}
                    value={values.search}
                    isSearchable
                    autoFocus
                  />
                )}
                {values.filterBy === "Comuna" && (
                  <CustomSelect
                  id="search"
                  label="Comuna"
                  placeholder="Seleccionar Comuna"
                  options={communes.map((commune) => ({
                    label: commune,
                    value: commune,
                  }))}
                  value={values.search}
                  isSearchable
                  onChange={(value: any) => {
                    setFieldValue("search", value);
                  }}
                />
                )}
                {/* {values.filterBy !== "Precio" &&
                  values.filterBy !== "Fecha" &&
                  values.filterBy !== "Region" &&
                  values.filterBy !== "Categoria" &&
                  values.filterBy !== "Comuna" && (
                    <TextField
                      id="search"
                      divClassName="mt-3"
                      placeholder="Buscar..."
                      value={values.search}
                      onChange={handleChange}
                      helperText={touched.search && errors.search}
                      error={touched.search && Boolean(errors.search)}
                    />
                  )} */}
              </div>

              <div className="pt-0 "></div>
              {/* <span className=" font-bold pt-1 text-white text-center text-xs">
                Ej: Arreglo de calefont
              </span> */}
              <div className="pt-3 ">
                <Button
                  type="submit"
                  text="Filtrar"
                  fullWidth
                  color="secondary"
                  isLoading={RequestsState === FormState.Submitting}
                />
                <Button
                  text="Limpiar filtro"
                  className="mt-2 bg-redButton"
                  fullWidth
                  onClick={() => {
                    dispatch(getRequests());
                  }}
                />
              </div>
            </form>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
