import { format } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { FormState } from "../../../models/form_state";
import { PlaintiffOffer } from "../../../models/PlaintiffOffer";
import { deleteOffer } from "../../../redux/actions/offer.action";
import { OfferState } from "../../../redux/reducers/offer.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";

interface Props {
  offer: PlaintiffOffer;
}

export const MyOfferCard: React.FC<Props> = ({ offer }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate()

  const openModal = () => {
    setModal(true);
  };

  const handleOnClose = () => {
    setModal(false);
  };
  const handleDelete = () => {
    dispatch(deleteOffer(offer, offer.requestRef));
    handleOnClose();
    navigate("/oferente/ofertasRealizadas")
  };
  const {
    delete: { state: deleteState },
  } = useSelector<RootState, OfferState>((state) => state.offerReducer);


  return (
    <div className="mt-10">
      <h1 className="text-3xl text-deepBlue font-bold mb-5 ml-2">Mi Oferta</h1>
      <div className="bg-grayContainer rounded-xl p-5 m-2 flex flex-col font-semibold relative">
        <span className="text-lg text-blue-800 font-bold mb-2">
          Información de mi oferta:
        </span>
        <span className="text-base text-secondary-light">
          Titulo: <span className="text-gray-400">{offer.Titulo}</span>
        </span>
        <span className="text-base text-secondary-light">
          Presupuesto:{" "}
          <span className="text-gray-400">
            {offer?.Precio?.toLocaleString("es")}
          </span>
        </span>
        <span className="text-base text-secondary-light">
          Fecha Inicio:{" "}
          <span className="text-gray-400">
            {format(new Date(offer?.FechaInicio), "dd/MM/yyyy")}
          </span>
        </span>
        <span className="text-base text-secondary-light">
          Cantidad:{" "}
          <span className="text-gray-400">{`${offer?.Cantidad?.Cantidad} ${offer?.Cantidad?.Tipo}`}</span>
        </span>
      </div>

      <div className="bg-grayContainer rounded-xl p-5 m-2 flex flex-col font-semibold relative">
        <span className="text-lg text-blue-800 font-bold mb-2">
          Datos que se mostrarán al Solicitante:
        </span>
        <span className="textm-sm text-secondary-light">
          Nombre:{" "}
          <span className="text-gray-400">{`${offer.Usuario.Nombre} ${offer.Usuario.Apellido}`}</span>
        </span>
        <span className="textm-sm text-secondary-light">
          Dirección:
          <span className="text-gray-400"> {offer.Usuario.Direccion}</span>
        </span>
        <span className="textm-sm text-secondary-light">
          Email: <span className="text-gray-400">{offer.Usuario.Email}</span>
        </span>
        <span className="textm-sm text-secondary-light">
          Teléfono:
          <span className="text-gray-400">{`+56${offer.Usuario.Telefono}`}</span>
        </span>
      </div>
      <div className="bg-grayContainer rounded-xl p-5 m-2 flex flex-col font-semibold relative">
        <span className="text-lg text-blue-800 font-bold mb-2">Mensaje:</span>
        <span className="text-gray-400">{offer?.Mensaje}</span>
        <div className="mt-5 justify-center flex">
          <Button
            text="Cancelar Oferta"
            className="bg-redButton ml-5"
            paddingClassname="py-2 px-7"
            onClick={openModal}
            isLoading={deleteState === FormState.Submitting}
          />
        </div> 
      </div>
      <Modal isVisible={modal} onClose={handleOnClose} isDismiseble>
          <div className="bg-white rounded-lg h-full shadow-lg p-5 ">
            <h1 className="text-lg">¿Esta seguro de eliminar esta oferta?</h1>
            <div className="mt-5">
              <Button
                text="Aceptar"
                paddingClassname="py-2 px-10"
                className=" text-sm bg-primary-light"
                onClick={() => handleDelete()}
              />
              <Button
                text="Cancelar"
                paddingClassname="py-2 px-10"
                className="text-sm bg-redButton ml-5"
                onClick={handleOnClose}
              />
            </div>
          </div>
        </Modal>
    </div>
  );
};
