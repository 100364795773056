import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Button } from "../../../components/Button";
import { FileSpace } from "../../../components/FileSpace";
import { MenuItem } from "../../../components/MenuItem";
import { Modal } from "../../../components/Modal";
import { Select } from "../../../components/Select";
import { TextField } from "../../../components/TextField";
import { FormState } from "../../../models/form_states";
import { getCategories } from "../../../redux/actions/categories.action";
import { addOffer, offerAddInitial } from "../../../redux/actions/offer.action";
import { CategoriesState } from "../../../redux/reducers/categories.reducer";
import { OfferState } from "../../../redux/reducers/offer.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";

interface Props {
  requestId: string;
  requestCat: string;
}

export const MakeYourOfferForm: React.FC<Props> = ({ requestId, requestCat }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    add: { state },
  } = useSelector<RootState, OfferState>((state) => state.offerReducer);

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Precio: 0,
        FechaInicio: "",
        FechaTermino: "",
        Mensaje: "",
        Titulo: "",
        Cantidad: {
          Cantidad: 1,
          Tipo: "Unidad",
        },
      },
      onSubmit: (values) => {
        dispatch(
          addOffer(
            {
              ...values,
              Precio: parseInt("" + values.Precio),
              Categoria: requestCat
            },
            requestId
          )
        );
      },
      validationSchema: yup.object({
        Titulo: yup.string().required("Debe ingresar un titulo"),
        Precio: yup.string().required("Debe ingresar un precio"),
        FechaInicio: yup.string().required("Debe ingresar una fecha de inicio"),
        FechaTermino: yup
          .string()
          .required("Debe ingresar una fecha de termino"),
        Mensaje: yup.string().required("Debe ingresar un mensaje"),
        Cantidad: yup.object({
          Cantidad: yup
            .number()
            .min(1, "La cantidad debe ser mayor o igual a 1")
            .required("Debe ingresar unidades"),
          Tipo: yup.string().required("Debe ingresar un tipo"),
        }),
      }),
    });

  const [modal, setModal] = useState({
    open: false,
    text: "Oferta creada correctamente",
  });

  const openModal = (text: string) => {
    setModal({ open: true, text });
  };

  const handleCloseModal = () => {
    setModal({ open: false, text: "" });
    setTimeout(() => {
      navigate("/solicitudesActivas", { replace: true });
      dispatch(offerAddInitial());
    }, 50);
  };

  useEffect(() => {
    if (state === FormState.Success) {
      openModal("Oferta creada correctamente");
    }
  }, [state]);

  return (
    <div className="mt-20">
      <h1 className="text-3xl text-deepBlue font-bold">Haz tu oferta</h1>
      <form onSubmit={handleSubmit}>
        <div className=" bg-blue-100 rounded-xl p-5 flex flex-col">
          <TextField
            label="Título"
            placeholder="título"
            id="Titulo"
            divClassName="mb-4"
            value={values.Titulo}
            onChange={handleChange}
            helperText={touched.Titulo && errors.Titulo}
            error={touched.Titulo && Boolean(errors.Titulo)}
          />
          <TextField
            label="Indique fecha de inicio"
            id="FechaInicio"
            type="date"
            divClassName="mb-4 mt-2"
            value={values.FechaInicio}
            onChange={handleChange}
            helperText={touched.FechaInicio && errors.FechaInicio}
            error={touched.FechaInicio && Boolean(errors.FechaInicio)}
          />

          <TextField
            label="Indique fecha de término"
            id="FechaTermino"
            type="date"
            divClassName="mb-4 mt-2"
            value={values.FechaTermino}
            onChange={handleChange}
            helperText={touched.FechaTermino && errors.FechaTermino}
            error={touched.FechaTermino && Boolean(errors.FechaTermino)}
          />

          <TextField
            label="Indique presupueso sugerido"
            placeholder="precio"
            id="Precio"
            divClassName="mb-4 mt-2"
            value={values.Precio}
            onChange={handleChange}
            helperText={touched.Precio && errors.Precio}
            error={touched.Precio && Boolean(errors.Precio)}
          />

          <div className="grid grid-cols-2 gap-4 mt-2">
            <Select
              id="Cantidad.Tipo"
              label="Tipo"
              placeholder="Seleccionar tipo"
              value={values.Cantidad.Tipo}
              helperText={touched?.Cantidad?.Tipo && errors?.Cantidad?.Tipo}
              error={touched?.Cantidad?.Tipo && Boolean(errors?.Cantidad?.Tipo)}
              onChange={(value) => {
                setFieldValue("Cantidad.Tipo", value);
              }}
            >
              {[
                "milímetro",
                "centímetro",
                "pulgada",
                "metro",
                "kilómetro",
                "metro cuadrado",
                "hectárea",
                "kilómetro cuadrado",
                "centímetro cúbico",
                "metro cúbico",
                "mililitro",
                "litro",
                "gramo",
                "kilogramo",
                "tonelada",
                "minuto",
                "hora",
                "kilovatio-hora",
                "día",
                "unidad",
                "mes",
                "año",
              ].map((Tipo, index) => (
                <MenuItem key={`Tipo-${index}`} value={Tipo} label={Tipo} />
              ))}
            </Select>

            <TextField
              label="Cantidad"
              placeholder="Cantidad"
              id="Cantidad.Cantidad"
              value={values.Cantidad.Cantidad}
              onChange={handleChange}
              helperText={
                touched?.Cantidad?.Cantidad && errors?.Cantidad?.Cantidad
              }
              error={
                touched?.Cantidad?.Cantidad &&
                Boolean(errors?.Cantidad?.Cantidad)
              }
              type="number"
            />
          </div>
          <TextField
            label="Mensaje"
            placeholder="mensaje"
            id="Mensaje"
            multiline
            divClassName="mb-4 mt-3"
            value={values.Mensaje}
            onChange={handleChange}
            helperText={touched.Mensaje && errors.Mensaje}
            error={touched.Mensaje && Boolean(errors.Mensaje)}
          />
          <Button
            type="submit"
            text="Ofertar"
            className="bg-primary text-xl px-5"
            isLoading={state === FormState.Submitting}
            disabled={state === FormState.Submitting}
          />
        </div>
      </form>
      <Modal isVisible={modal.open} onClose={handleCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-sm font-semibold">{modal.text}</p>
          <Button
            text={"Ok"}
            className="mt-3 bg-primary-light text-white font-semibold"
            paddingClassname="py-1 px-3"
            onClick={handleCloseModal}
          />
        </div>
      </Modal>
    </div>
  );
};
