import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { X } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/Button";
import { IconButton } from "../../../components/IconButton";
import { Modal } from "../../../components/Modal";
import { FormState } from "../../../models/form_state";
import { PlaintiffRequest } from "../../../models/PlaintiffRequest";
import { getMoreRequests, getRequests } from "../../../redux/actions/requests.actions";
import { addTransaction } from "../../../redux/actions/transaction.action";
import { RequestsState } from "../../../redux/reducers/requests.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";
import { RequestCard } from "./components/RequestCard";
import { auth } from "../../../configs/firebase";
import { getAdvertising } from "../../../redux/actions/advertising.action";
import { AdvertisingState } from "../../../redux/reducers/advertising.reducer";

export const ListRequests = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [selectedRequest, setSelectedRequest] =
    useState<null | PlaintiffRequest>(null);

  const [open, setOpen] = useState(false);

  const formFormRef = useRef<HTMLFormElement | null>(null);
  const inputTokenFormRef = useRef<HTMLInputElement | null>(null);
  const buttonFormRef = useRef<HTMLButtonElement | null>(null);


  const [loadingPayment, setLoadingPayment] = useState(false);

  const handleOnClosePayModal = async () => {
    setLoadingPayment(true)
    try {
      const currentUser = auth.currentUser;
      const token = await currentUser?.getIdToken();

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,  // Adjunta el token en el header
          'Content-Type': 'application/json'   // Define el tipo de contenido
        }
      };

      const response = await Axios.post(
        "https://us-central1-demandate-77297.cloudfunctions.net/app/webpay_plus/create",
        {
          buyId: selectedRequest?.id,
          amount: 990,
          userId: auth.currentUser?.uid,
        },
        config
      );
      if (!formFormRef.current) return;

      if (!inputTokenFormRef.current) return;

      if (!buttonFormRef.current) return;
      formFormRef.current.action = response.data.url;
      inputTokenFormRef.current.value = response.data.token;
      buttonFormRef.current.click();
    } catch (error) {
      //console.log(error);
    } finally{
      setLoadingPayment(false)
    }
    handleOnClose();
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleFeaturedClick = (request: PlaintiffRequest) => {
    setOpen(true);
    setSelectedRequest(request);
  };

  const {
    state,
    totalDocs,
    requests,
    delete: { state: deleteState },
  } = useSelector<RootState, RequestsState>((state) => state.requestReducer);

  const moreRequest = (total: any) => {
    dispatch(getMoreRequests(total));
  };

  useEffect(() => {
    dispatch(getRequests());
    // eslint-disable-next-line
  }, [deleteState]);

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Solicitudes Realizadas" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Solicitudes Realizadas" && x.Posicion === "inferior"
  );

  return (
    <main className="py-10 px-8 lg:container lg:mx-auto">
      <Helmet title="Solicitudes Realizadas" />
      <h1 className=" text-3xl font-bold text-deepBlue ">
        {`Solicitudes Realizadas (${totalDocs})`}
      </h1>
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:grid-cols-4 2xl:grid-cols-5 mt-8">
        {state === FormState.Submitting || state === FormState.Initial ? (
          new Array(5).fill(0).map((value, i) => {
            return (
              <div
                className="h-96 bg-white shadow-md rounded-xl overflow-hidden"
                key={`skeleton-div-${i}`}
              >
                <div className="w-full h-1/2 bg-gray-200 animate-pulse"></div>

                <div className="w-full h-1/2 flex flex-col ">
                  <div className="w-3/4 h-4 pulse rounded-full bg-gray-200 mt-2 mx-4"></div>
                  <div className="mx-4 mt-10">
                    <div className="w-3/4 h-3 pulse rounded-full bg-gray-200"></div>
                    <div className="w-2/4 h-2 pulse rounded-full bg-gray-200 mt-2"></div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <React.Fragment>
            {requests.map((request, index) => (
              <RequestCard
                key={`request-${index}`}
                request={request}
                onFeaturedClick={handleFeaturedClick}
              />
            ))}
          </React.Fragment>
        )}
      </div>
      <div className="mb-10 flex items-center justify-center ">
        <Button
          type="submit"
          text="Cargar más solicitudes"
          color="secondary"
          className="mt-10  flex items-center justify-center "
          onClick={() => moreRequest(totalDocs)}
        />
      </div>
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
      <form method="POST" style={{ display: "none" }} ref={formFormRef}>
        <input ref={inputTokenFormRef} type="hidden" name="token_ws" />
        <button ref={buttonFormRef} type="submit" value="Pagar" />
      </form>
      <Modal isVisible={open} onClose={handleOnClose} isDismiseble>
        <div className="bg-white rounded-lg h-full shadow-lg pb-5">
          <div className="flex flex-row-reverse">
            <IconButton onClick={handleOnClose}>
              <X size={32} className="text-secondary" />
            </IconButton>
          </div>
          <div className="px-4 flex flex-col items-center">
            <h1 className="font-semibold text-2xl text-secondary mb-5">
              Destacar Aviso
            </h1>
            <h2 className="font-semibold text-primary">
              Destaca tu aviso por 7 días.
            </h2>

            <p className="font-semibold text-2xl mt-4 text-secondary">$990</p>
            <img
              src="/assets/img/logo-web-pay-plus.png"
              alt="imagen-webpay"
              className="w-2/4"
            />
            <Button
              text="Ir a pagar"
              onClick={handleOnClosePayModal}
              isLoading={loadingPayment}
              className="bg-primary w-2/4 mt-6"
            />
          </div>
        </div>
      </Modal>
    </main>
  );
};
